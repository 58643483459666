
export default {
  name: 'MenuHambourgeois',
  props: {
    etat: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    action: function () {
      return document.querySelector('.hambourgeois--etat')
    }
  },
  watch: {
    etat: function (nouvelEtat) {
      const classe = 'hambourgeois--etat-actif'
      if (nouvelEtat) {
        this.action.classList.add(classe)
      } else {
        this.action.classList.remove(classe)
      }
    }
  },
  mounted () {
    document.body.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        if (this.action.classList.contains('hambourgeois--etat-actif')) {
          this.exporterClick()
        }
      }
    })
  },
  methods: {
    exporterClick: function () {
      this.$emit('ouvrir-menu')
    }
  }
}
