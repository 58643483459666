
export default {
  props: {
    couleur: {
      type: String,
      required: true
    },
    nom: {
      type: String,
      default: 'external-link'
    },
    hauteur: {
      type: Number,
      default: 24
    },
    margeDroite: {
      type: Number,
      default: 0
    },
    margeGauche: {
      type: Number,
      default: 0
    },
    uniteMarge: {
      type: String,
      default: 'px'
    }
  },
  computed: {
    taille () {
      return this.hauteur + 'px'
    },
    style () {
      return {
        'mask-size': 'contain',
        'mask-repeat': 'no-repeat',
        'mask-image': 'url(' + require('@/assets/icons/' + this.nom + '.svg') + ')',
        display: 'inline-block',
        'min-height': this.hauteur + 'px',
        'min-width': this.hauteur + 'px',
        'margin-right': this.margeDroite + this.uniteMarge,
        'margin-left': this.margeGauche + this.uniteMarge,
        '-webkit-mask-size': 'contain',
        '-webkit-mask-repeat': 'no-repeat',
        '-webkit-mask-image': 'url(' + require('@/assets/icons/' + this.nom + '.svg') + ')'
      }
    },
    couleurIcone () {
      return 'fond--' + this.couleur
    }
  }
}
