
import { langue } from '~/assets/js/utiles.js'

export default {
  data () {
    return {
      nousJoindre: {},
      onglets: [],
      popupTemoins: {},
      politiques: []
    }
  },
  async fetch () {
    await this.initialiserNavigation()
  },
  watch: {
    '$i18n.locale': function () {
      this.initialiserNavigation()
    }
  },
  methods: {
    initialiserNavigation: async function () {
      const filtreTraduction = {
        _filter: {
          languages_code: {
            _eq: langue(this.$i18n.locale)
          }
        }
      }

      // Initialisation de la composante PolitiqueTemoins
      const collectionTemoins = this.$directus.items('politique_temoins')
      const resultatsTemoins = await collectionTemoins.readByQuery({
        fields: [
          'translations.titre',
          'translations.texte',
          'translations.texte_bouton'
        ],
        filter: {
          version_active: {
            _eq: true
          }
        },
        deep: {
          translations: filtreTraduction
        }
      })

      this.popupTemoins = resultatsTemoins.data[0].translations[0]

      // Initialisation des politiques
      const collectionPolitiques = this.$directus.items('sous_section')
      const politiquesBrutes = await collectionPolitiques.readByQuery({
        fields: [
          'translations.titre',
          'translations.url',
          'section.translations.titre',
          'section.translations.url',
          'pages.translations.titre',
          'pages.translations.url'
        ],
        filter: {
          nom: {
            _eq: 'Politiques'
          }
        },
        deep: {
          translations: filtreTraduction,
          section: {
            translations: filtreTraduction
          },
          pages: {
            _filter: {
              active: {
                _eq: true
              }
            },
            translations: filtreTraduction
          }
        },
        limit: 1
      })

      this.politiques = []
      const politiqueBrute = politiquesBrutes.data[0]
      const urlParent = politiqueBrute.section.translations[0].url + '/' + politiqueBrute.translations[0].url
      politiqueBrute.pages.forEach(page => {
        this.politiques.push({
          titre: page.translations[0].titre,
          url: '/' + urlParent + '/' + page.translations[0].url
        })
      })

      // Initialisation du menu de navigation
      const collectionSections = this.$directus.items('section')
      const resultats = await collectionSections.readByQuery({
        fields: [
          'translations.titre',
          'translations.url',
          'sous_sections.translations.titre',
          'sous_sections.translations.url',
          'sous_sections.pages.translations.titre',
          'sous_sections.pages.translations.url'
        ],
        filter: {
          visible_dans_menu: {
            _eq: true
          }
        },
        deep: {
          translations: filtreTraduction,
          sous_sections: {
            translations: filtreTraduction,
            pages: {
              translations: filtreTraduction,
              _filter: {
                active: {
                  _eq: true
                }
              }
            }
          }
        },
        limit: -1
      })

      this.onglets = []
      this.onglets.push({ nom: this.$t('Accueil') })

      resultats.data.forEach(onglet => {
        const traductionOnglet = onglet.translations[0]

        const sections = []
        onglet.sous_sections.forEach(section => {
          const traductionSousSection = section.translations[0]
          const urlSousSection = '/' + traductionOnglet.url + '/' + traductionSousSection.url

          const pages = []
          section.pages.forEach(page => {
            pages.push({
              nom: page.translations[0].titre,
              url: urlSousSection + '/' + page.translations[0].url
            })
          })

          if (traductionSousSection.titre === this.$t('Nous joindre')) {
            this.nousJoindre = {
              nom: traductionSousSection.titre,
              url: urlSousSection
            }
          }

          sections.push({
            nom: traductionSousSection.titre,
            url: urlSousSection,
            sousSections: pages
          })
        })

        this.onglets.push({
          nom: onglet.translations[0].titre,
          sections: sections,
          estOuvert: false
        })
      })
    }
  }
}
