
export default {
  props: {
    nousJoindre: {
      type: Object,
      required: true,
      validator: function (value) {
        return ((typeof value.nom === 'string' && value.nom.length) && (typeof value.url === 'string' && value.url.length))
      }
    },
    politiques: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      paramsIcone: {
        hauteur: 16,
        marge: 10,
        uniteMarge: 'px'
      }
    }
  }
}
