export const construireRequeteDirectus = (requete, variables) => {
  return {
    query: requete,
    variables: variables
  }
}

export const langue = (lang) => { return lang + '-CA' }
export const convertirLangue = (lang) => { return lang.split('-')[0] }

export const initialiserComponents = (contenu) => {
  const he = require('he')

  const regexComposante = /(?:<p>)?{{(.[^{}]*?)\/}}(?:<\/p>)?/g
  const regexComposanteAvecFermeture = /(?:<p>)?{{([a-zA-Z]*)(.[^\/]*?)?}}(?:<\/p>)?((.|\n)*?)(?:<p>)?{{\/\1}}(?:<\/p>)?/g

  contenu = contenu.replaceAll(regexComposante, '<$1/>')

  // Étant donnée qu'on peut avoir des composante dans une composante, nous vérifions qu'il n'en reste plus.
  while (contenu.match(regexComposanteAvecFermeture)) {
    contenu = contenu.replaceAll(regexComposanteAvecFermeture, '<$1 $2>$3</$1>')
  }

  return he.decode(contenu)
}

export const getTemoins = (nomTemoin, contenuTemoins) => {
  nomTemoin = nomTemoin + '='
  const temoinsDecodes = decodeURIComponent(contenuTemoins)
  const temoins = temoinsDecodes.split(';')
  for (let i = 0; i < temoins.length; i++) {
    let temoinsCourant = temoins[i]
    while (temoinsCourant.charAt(0) === ' ') {
      temoinsCourant = temoinsCourant.substring(1)
    }
    if (temoinsCourant.indexOf(nomTemoin) === 0) {
      return temoinsCourant.substring(nomTemoin.length, temoinsCourant.length)
    }
  }
  return false
}

export const initialiserTableauResponsive = () => {
  const tables = document.querySelectorAll('table')

  tables.forEach(table => {
    const entetes = table.querySelectorAll('thead td')
    const titreTableau = table.querySelector('table caption')

    if (titreTableau) {
      titreTableau.style.visibility = 'visible'
    } else {
      // On veut être en mesure d'avoir un titre en format mobile si le tableau en a pas
      table.appendChild(document.createElement('caption'))
      table.querySelector('caption').innerText = entetes[0].innerText
    }

    let celluleEttendue = null
    const lignes = table.querySelectorAll('tbody tr')
    let nbCellulesIndiceZero = 0 // Nb de cellules réellement visible à l'indice 0 d'une ligne
    lignes.forEach((ligne, indiceLigne) => {
      const cellules = ligne.querySelectorAll('tbody td')
      const classe = (nbCellulesIndiceZero % 2) ? 'ligne_pair' : 'ligne_impair'

      cellules.forEach((cellule, indiceCellule) => {
        const rowspan = cellule.getAttribute('rowspan')
        let entete = ''

        if (entetes.length > 0) {
          entete = entetes[indiceCellule].innerText
        }

        cellule.innerHTML = `<div>${cellule.innerHTML}</div>`

        if (rowspan) {
          celluleEttendue = new CelluleEttendueVerticale(rowspan, indiceCellule, indiceLigne, cellule)
          nbCellulesIndiceZero++
        } else if (celluleEttendue) {
          celluleEttendue.genererCellulePourMobile(indiceCellule, entete, ligne)
        } else if (indiceCellule === 0) {
          nbCellulesIndiceZero++
        }

        if (indiceCellule > 0 || titreTableau) {
          if (celluleEttendue && celluleEttendue.indiceCelluleDoitEtreAjustee(indiceCellule, indiceLigne)) {
            entete = entetes[++indiceCellule].innerText
          }

          cellule.setAttribute('data-label', entete)
        }
      })

      if (celluleEttendue && celluleEttendue.continuerPropagation()) {
        celluleEttendue.decrementerEttendue()

        if (celluleEttendue.finPropagation()) {
          celluleEttendue.ajouterClasse(classe)
        }
      }
    })
  })
}

class CelluleEttendueVerticale {
  // Une cellule sur plusieurs lignes est une cellule qui contient un rowspan
  constructor (ettendue, position, numLigne, cellule) {
    this.ettendue = ettendue // corrélation direct avec le rowspan
    this.position = position // position sur la ligne
    this.cellule = cellule
    this.contenu = cellule.innerText
    this.numLigne = numLigne
  }

  ajouterClasse (classe) {
    this.cellule.classList.add(classe)
  }

  continuerPropagation () {
    return this.ettendue > 0
  }

  finPropagation () {
    return this.ettendue === 0
  }

  indiceCelluleDoitEtreAjustee (positionCellule, indiceLigne) {
    return this.continuerPropagation() && positionCellule >= this.position && indiceLigne !== this.numLigne
  }

  decrementerEttendue () {
    this.ettendue--
  }

  genererCellulePourMobile (positionCellule, enteteCourante, ligne) {
    if (this.continuerPropagation() && this.position === positionCellule) {
      const cellule = ligne.insertCell(this.position)
      cellule.innerHTML = this.contenu
      cellule.setAttribute('data-label', enteteCourante)
      cellule.classList.add('cellule-pour-mobile')
    }
  }
}
