export const sessionsPossiblesTremplinDec = ['automne', 'hiver']

export const offreDeCours = ['jour', 'soir']

export const identitesPossibles = {
  REG: 'Les candidatures régulières',
  INT: 'Les candidatures internationales',
  FCO: 'Les candidatures à la formation continue',
  INTERVENANT: 'Je suis un.e intervenant.e du milieu scolaire'
}

export const ZONE_SECURISEE_FR = '/zones-securisees/'
export const ZONE_SECURISEE_EN = '/secure-areas/'

export const typesAutorisations = {
  ZONE_REGISTRAIRE: 'zone_registraire',
  ZONE_CO_CISEP: 'zone_co-cisep',
  ZONE_PROFESSIONNEL: 'zone_professionnel'
}

export const urlConnexionParLangue = {
  fr: `${ZONE_SECURISEE_FR}connexion`,
  en: `${ZONE_SECURISEE_EN}login`
}

export const urlZonesSecuriseesParLangue = {
  fr: {
    [typesAutorisations.ZONE_REGISTRAIRE]: `${ZONE_SECURISEE_FR}registraire`,
    [typesAutorisations.ZONE_CO_CISEP]: `${ZONE_SECURISEE_FR}co-cisep`,
    [typesAutorisations.ZONE_PROFESSIONNEL]: `${ZONE_SECURISEE_FR}pro-collegial`
  },
  en: {
    [typesAutorisations.ZONE_REGISTRAIRE]: `${ZONE_SECURISEE_EN}registraire`,
    [typesAutorisations.ZONE_CO_CISEP]: `${ZONE_SECURISEE_EN}counsellor`,
    [typesAutorisations.ZONE_PROFESSIONNEL]: `${ZONE_SECURISEE_EN}cegep-pros`
  }
}
