
export default {
  name: 'SectionsMenuNavigation',
  props: {
    sections: {
      type: Array,
      default: function () {
        return []
      }
    },
    parentEstOuvert: {
      type: Boolean,
      default: false
    },
    parentDeclencheurId: {
      type: String,
      required: true
    }
  },
  computed: {
    classeOuverture: function () {
      const ouvert = 'deroulant--ouvert'
      const ferme = 'deroulant--ferme'

      let classe = ferme
      if (this.parentEstOuvert) {
        const sectionActive = document.querySelector(ouvert)

        if (sectionActive) {
          sectionActive.classList.add(ferme)
          sectionActive.classList.remove(ouvert)
        }
        classe = ouvert
      }
      return classe
    },
    tabIndex: function () {
      // Quand des éléments du menu sont cachés, ils doivent absolument être en display None ou en tabIndex="-1".
      return (this.parentEstOuvert) ? 0 : -1
    }
  },
  methods: {
    emettreEvenement () {
      this.$emit('changement-page')
    }
  }
}
