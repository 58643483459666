
export default {
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  computed: {
    textePageIntrouvable: function () {
      return this.$t('texte_page_introuvable')
    }
  }
}
