
import SectionsMenuNavigation from '@/components/SectionsMenuNavigation.vue'
import MenuHambourgeois from '@/components/MenuHambourgeois.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'MenuNavigation',
  components: {
    MenuHambourgeois,
    SectionsMenuNavigation
  },
  props: {
    onglets: {
      type: Array,
      required: true
    },
    nousJoindre: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      estTabletteOuMobile: false,
      menuEstOuvert: false,
      ongletsCourants: this.onglets,
      derniereLargeurEnregistree: 0
    }
  },
  computed: {
    tabIndex: function () {
      // Quand tous les index sont à 0, le navigateur semble savoir comment les gérer.
      // En version ordinateur, les onglets principaux sont toujours à 0.
      const varianteEcran = (this.estTabletteOuMobile) ? -1 : 0
      return (this.menuEstOuvert) ? 0 : varianteEcran
    }
  },
  watch: {
    onglets: function () {
      this.ongletsCourants = this.onglets
    }
  },
  mounted () {
    window.addEventListener('load', () => {
      this.majEstTabletteOuMobile()
    })

    window.addEventListener('resize', debounce(this.majEstTabletteOuMobile, 50))

    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        // Permet la fermeture d'un onglet ouvert en version ordinateur
        this.controleurOngletOuvert()
      }
    })
  },
  methods: {
    majChevron (ongletEstOuverte) {
      return (ongletEstOuverte) ? 'chevron-top' : 'chevron-bottom'
    },
    majEstTabletteOuMobile () {
      if (window.innerWidth !== this.derniereLargeurEnregistree) {
        this.derniereLargeurEnregistree = window.innerWidth
        this.estTabletteOuMobile = (window.innerWidth < 992)

        this.controleurOngletOuvert()
        this.fermerMenu()
      }
    },
    controleurOngletOuvert (ongletCourant = null) {
      const ongletActif = document.querySelector('.menu__onglet--actif')
      if (ongletActif) {
        const indexSectionActive = parseInt(ongletActif.id.replace('onglet-choix', ''))
        if (ongletCourant !== ongletActif || ongletCourant === null) {
          // Dans un cas où l'onglet actif n'a pas été fermé par l'usager
          this.ongletsCourants[indexSectionActive].estOuvert = false
        }
      }
    },
    deroulerOnglet (onglet, index) {
      const ongletCourant = document.getElementById('onglet-choix' + index)

      if (index > 0) { // On ignore l'accueil
        this.controleurOngletOuvert(ongletCourant)
        onglet.estOuvert = !onglet.estOuvert
      } else {
        this.controleurOngletOuvert()
        this.fermerMenu()
      }
    },
    deroulerMenu () {
      const menu = document.getElementById('menu')
      const classe = 'menu--ouvert'

      this.menuEstOuvert = !(this.menuEstOuvert && menu.classList.contains(classe))
      if (!this.menuEstOuvert) {
        this.controleurOngletOuvert()
      }
    },
    fermerMenu () {
      this.menuEstOuvert = false
    }
  }
}
