
export default {
  name: 'LienBoutonIcone',
  props: {
    texte: {
      type: String,
      default: null
    },
    texteCouleur: {
      type: String,
      default: 'blanc'
    },
    iconeVersGauche: {
      type: Boolean,
      default: false
    },
    nomIcone: {
      type: String,
      default: 'external-link'
    },
    lien: {
      type: String,
      required: true
    },
    telephone: {
      type: String,
      default: null
    },
    couleur: {
      type: String,
      default: 'bleu-marine'
    },
    bordureCouleur: {
      type: String,
      default: null
    }
  },
  computed: {
    style () {
      const classes = []
      const bordure = 'bordure--'

      classes.push((this.bordureCouleur) ? bordure + this.bordureCouleur : bordure + this.couleur)
      classes.push('fond--' + this.couleur)
      classes.push('texte--' + this.texteCouleur)
      return classes
    },
    couleurIcone () {
      return this.texteCouleur
    },
    constructionLien () {
      return (this.telephone) ? 'tel:' + this.telephone : this.lien
    },
    marge () {
      return this.texte ? 10 : 0
    }
  }
}
