import { Directus } from '@directus/sdk'

export default ({ $config: { baseUrl, directusUrl } }, inject) => {
  let directus = null

  if (process.server) {
    directus = new Directus(directusUrl)
  } else {
    directus = new Directus(`${baseUrl}/directus`)
  }
  inject('directus', directus)
}
