import { urlConnexionParLangue, urlZonesSecuriseesParLangue } from '~/assets/js/constantes.js'

export default async function ({ i18n, localePath, route, redirect, res, $axios }) {
  const urlConnexion = localePath(urlConnexionParLangue[i18n.locale])

  let permissionsUsager

  try {
    permissionsUsager = await $axios.$get('/auth/permissions-usager')
  } catch (error) {
    if (error.response.status === 401) {
      if (route.fullPath.startsWith(urlConnexion)) {
        return
      } else {
        return redirect(`${urlConnexion}?redirection_apres_connexion=${encodeURI(route.fullPath)}`)
      }
    } else {
      throw error
    }
  }

  // Met à jour le témoin des autorisations de l'usager
  const valeurCookie = `autorisations=${JSON.stringify(permissionsUsager)}`
  if (process.server) {
    res.setHeader('Set-Cookie', [valeurCookie])
  } else {
    document.cookie = valeurCookie
  }

  if (route.fullPath.startsWith(urlConnexion)) {
    if (permissionsUsager.length > 0) {
      const urlZonesSecurisees =
                urlZonesSecuriseesParLangue[i18n.locale]
      return redirect(localePath(urlZonesSecurisees[permissionsUsager[0]]))
    } else {
      // L'usager n'a accès à aucune zone sécurisée
      // TODO Voir si ca ne serait pas mieux d'avoir une page d'erreur "Accès interdit"
      return redirect('/')
    }
  } else {
    const requeteZonesSecurisees = {
      fields: [
        'type_autorisation'
      ],
      filter: {
        translations: {
          url: {
            _eq: route.params.zone
          }
        }
      }
    }
    const { type_autorisation: typeAutorisation } = await $axios.$post('/auth/donnees-securisees', {
      collection: 'zones_securisees',
      requete: JSON.stringify(requeteZonesSecurisees)
    }).then(result => result.data[0])

    if (!permissionsUsager.includes(typeAutorisation)) {
      // L'usager n'a pas accès a cette page, on le retourne à l'accueil
      // TODO Voir si ca ne serait pas mieux d'avoir une page d'erreur "Accès interdit"
      return redirect('/')
    }
  }
}
