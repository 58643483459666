import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2717d22f = () => interopDefault(import('../pages/recherche.vue' /* webpackChunkName: "pages/recherche" */))
const _36bdb377 = () => interopDefault(import('../pages/zones-securisees/connexion.vue' /* webpackChunkName: "pages/zones-securisees/connexion" */))
const _147c8a08 = () => interopDefault(import('../pages/zones-securisees/_zone/index.vue' /* webpackChunkName: "pages/zones-securisees/_zone/index" */))
const _5e82ff98 = () => interopDefault(import('../pages/zones-securisees/_zone/_page.vue' /* webpackChunkName: "pages/zones-securisees/_zone/_page" */))
const _042f6cac = () => interopDefault(import('../pages/_section/_soussection/index.vue' /* webpackChunkName: "pages/_section/_soussection/index" */))
const _6cf0b148 = () => interopDefault(import('../pages/_section/_soussection/_page.vue' /* webpackChunkName: "pages/_section/_soussection/_page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _482400f4,
    name: "index___en"
  }, {
    path: "/fr",
    component: _482400f4,
    name: "index___fr"
  }, {
    path: "/en/search",
    component: _2717d22f,
    name: "recherche___en"
  }, {
    path: "/fr/recherche",
    component: _2717d22f,
    name: "recherche___fr"
  }, {
    path: "/en/secure-areas/login",
    component: _36bdb377,
    name: "zones-securisees-connexion___en"
  }, {
    path: "/fr/zones-securisees/connexion",
    component: _36bdb377,
    name: "zones-securisees-connexion___fr"
  }, {
    path: "/en/secure-areas/:zone",
    component: _147c8a08,
    name: "zones-securisees-zone___en"
  }, {
    path: "/fr/zones-securisees/:zone",
    component: _147c8a08,
    name: "zones-securisees-zone___fr"
  }, {
    path: "/en/secure-areas/:zone/:page",
    component: _5e82ff98,
    name: "zones-securisees-zone-page___en"
  }, {
    path: "/fr/zones-securisees/:zone/:page",
    component: _5e82ff98,
    name: "zones-securisees-zone-page___fr"
  }, {
    path: "/en/:section/:soussection",
    component: _042f6cac,
    name: "section-soussection___en"
  }, {
    path: "/fr/:section/:soussection",
    component: _042f6cac,
    name: "section-soussection___fr"
  }, {
    path: "/en/:section/:soussection?/:page",
    component: _6cf0b148,
    name: "section-soussection-page___en"
  }, {
    path: "/fr/:section/:soussection?/:page",
    component: _6cf0b148,
    name: "section-soussection-page___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
