export const AnneeSessionSram = () => import('../../components/AnneeSessionSram.vue' /* webpackChunkName: "components/annee-session-sram" */).then(c => wrapFunctional(c.default || c))
export const BlocAccueilEntete = () => import('../../components/BlocAccueilEntete.vue' /* webpackChunkName: "components/bloc-accueil-entete" */).then(c => wrapFunctional(c.default || c))
export const BlocAncre = () => import('../../components/BlocAncre.vue' /* webpackChunkName: "components/bloc-ancre" */).then(c => wrapFunctional(c.default || c))
export const BlocCegep = () => import('../../components/BlocCegep.vue' /* webpackChunkName: "components/bloc-cegep" */).then(c => wrapFunctional(c.default || c))
export const BlocDeDate = () => import('../../components/BlocDeDate.vue' /* webpackChunkName: "components/bloc-de-date" */).then(c => wrapFunctional(c.default || c))
export const BlocEntete = () => import('../../components/BlocEntete.vue' /* webpackChunkName: "components/bloc-entete" */).then(c => wrapFunctional(c.default || c))
export const BlocPorteOuverte = () => import('../../components/BlocPorteOuverte.vue' /* webpackChunkName: "components/bloc-porte-ouverte" */).then(c => wrapFunctional(c.default || c))
export const BoiteDeSelection = () => import('../../components/BoiteDeSelection.vue' /* webpackChunkName: "components/boite-de-selection" */).then(c => wrapFunctional(c.default || c))
export const BoutonRetourHautDePage = () => import('../../components/BoutonRetourHautDePage.vue' /* webpackChunkName: "components/bouton-retour-haut-de-page" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CarteAccueil = () => import('../../components/CarteAccueil.vue' /* webpackChunkName: "components/carte-accueil" */).then(c => wrapFunctional(c.default || c))
export const ChangeurLangue = () => import('../../components/ChangeurLangue.vue' /* webpackChunkName: "components/changeur-langue" */).then(c => wrapFunctional(c.default || c))
export const CibleAncre = () => import('../../components/CibleAncre.vue' /* webpackChunkName: "components/cible-ancre" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FormulaireConnexion = () => import('../../components/FormulaireConnexion.vue' /* webpackChunkName: "components/formulaire-connexion" */).then(c => wrapFunctional(c.default || c))
export const FormulaireInformations = () => import('../../components/FormulaireInformations.vue' /* webpackChunkName: "components/formulaire-informations" */).then(c => wrapFunctional(c.default || c))
export const GrilleDeLiens = () => import('../../components/GrilleDeLiens.vue' /* webpackChunkName: "components/grille-de-liens" */).then(c => wrapFunctional(c.default || c))
export const GrilleOutils = () => import('../../components/GrilleOutils.vue' /* webpackChunkName: "components/grille-outils" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Icone = () => import('../../components/Icone.vue' /* webpackChunkName: "components/icone" */).then(c => wrapFunctional(c.default || c))
export const IconesTremplinDec = () => import('../../components/IconesTremplinDec.vue' /* webpackChunkName: "components/icones-tremplin-dec" */).then(c => wrapFunctional(c.default || c))
export const InfoAvecAction = () => import('../../components/InfoAvecAction.vue' /* webpackChunkName: "components/info-avec-action" */).then(c => wrapFunctional(c.default || c))
export const InfoNonRefermable = () => import('../../components/InfoNonRefermable.vue' /* webpackChunkName: "components/info-non-refermable" */).then(c => wrapFunctional(c.default || c))
export const InfoRefermable = () => import('../../components/InfoRefermable.vue' /* webpackChunkName: "components/info-refermable" */).then(c => wrapFunctional(c.default || c))
export const LegendeIcones = () => import('../../components/LegendeIcones.vue' /* webpackChunkName: "components/legende-icones" */).then(c => wrapFunctional(c.default || c))
export const LienAncre = () => import('../../components/LienAncre.vue' /* webpackChunkName: "components/lien-ancre" */).then(c => wrapFunctional(c.default || c))
export const LienBasDePage = () => import('../../components/LienBasDePage.vue' /* webpackChunkName: "components/lien-bas-de-page" */).then(c => wrapFunctional(c.default || c))
export const LienBase = () => import('../../components/LienBase.vue' /* webpackChunkName: "components/lien-base" */).then(c => wrapFunctional(c.default || c))
export const LienBoutonIcone = () => import('../../components/LienBoutonIcone.vue' /* webpackChunkName: "components/lien-bouton-icone" */).then(c => wrapFunctional(c.default || c))
export const LienTelechargement = () => import('../../components/LienTelechargement.vue' /* webpackChunkName: "components/lien-telechargement" */).then(c => wrapFunctional(c.default || c))
export const ListeDesCegepsSram = () => import('../../components/ListeDesCegepsSram.vue' /* webpackChunkName: "components/liste-des-cegeps-sram" */).then(c => wrapFunctional(c.default || c))
export const MapGoogleSRAM = () => import('../../components/MapGoogleSRAM.vue' /* webpackChunkName: "components/map-google-s-r-a-m" */).then(c => wrapFunctional(c.default || c))
export const MenuHambourgeois = () => import('../../components/MenuHambourgeois.vue' /* webpackChunkName: "components/menu-hambourgeois" */).then(c => wrapFunctional(c.default || c))
export const MenuNavigation = () => import('../../components/MenuNavigation.vue' /* webpackChunkName: "components/menu-navigation" */).then(c => wrapFunctional(c.default || c))
export const MenuZonesSecurisees = () => import('../../components/MenuZonesSecurisees.vue' /* webpackChunkName: "components/menu-zones-securisees" */).then(c => wrapFunctional(c.default || c))
export const Note = () => import('../../components/Note.vue' /* webpackChunkName: "components/note" */).then(c => wrapFunctional(c.default || c))
export const PolitiqueTemoins = () => import('../../components/PolitiqueTemoins.vue' /* webpackChunkName: "components/politique-temoins" */).then(c => wrapFunctional(c.default || c))
export const Prefooter = () => import('../../components/Prefooter.vue' /* webpackChunkName: "components/prefooter" */).then(c => wrapFunctional(c.default || c))
export const RapportAnnuel = () => import('../../components/RapportAnnuel.vue' /* webpackChunkName: "components/rapport-annuel" */).then(c => wrapFunctional(c.default || c))
export const RegroupementCentre = () => import('../../components/RegroupementCentre.vue' /* webpackChunkName: "components/regroupement-centre" */).then(c => wrapFunctional(c.default || c))
export const RegroupementGauche = () => import('../../components/RegroupementGauche.vue' /* webpackChunkName: "components/regroupement-gauche" */).then(c => wrapFunctional(c.default || c))
export const RegroupementLiensBasDePage = () => import('../../components/RegroupementLiensBasDePage.vue' /* webpackChunkName: "components/regroupement-liens-bas-de-page" */).then(c => wrapFunctional(c.default || c))
export const ResultatRecherche = () => import('../../components/ResultatRecherche.vue' /* webpackChunkName: "components/resultat-recherche" */).then(c => wrapFunctional(c.default || c))
export const SectionsMenuNavigation = () => import('../../components/SectionsMenuNavigation.vue' /* webpackChunkName: "components/sections-menu-navigation" */).then(c => wrapFunctional(c.default || c))
export const Sigle = () => import('../../components/Sigle.vue' /* webpackChunkName: "components/sigle" */).then(c => wrapFunctional(c.default || c))
export const TableauPortesOuvertes = () => import('../../components/TableauPortesOuvertes.vue' /* webpackChunkName: "components/tableau-portes-ouvertes" */).then(c => wrapFunctional(c.default || c))
export const TableauTremplinDec = () => import('../../components/TableauTremplinDec.vue' /* webpackChunkName: "components/tableau-tremplin-dec" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
