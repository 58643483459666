import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCMxga2u7HckQY4o6C1gTLyZ5_rsNGn7Ew',
    map_id: '7e81341caf3bb31d',
    libraries: 'places'
  }
})
