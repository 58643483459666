
export default {
  props: {
    onglets: {
      type: Array,
      required: true
    },
    nousJoindre: {
      type: Object,
      required: true,
      validator: function (value) {
        return ((typeof value.nom === 'string' && value.nom.length) && (typeof value.url === 'string' && value.url.length))
      }
    }
  },
  data () {
    return {
      actionAbsente: false,
      ouvrirRecherche: false,
      rechercheVisible: false,
      estMobile: false,
      chaineRecherche: ''
    }
  },
  computed: {
    styleBouton: function () {
      return this.ouvrirRecherche ? 'bordure--bleu-marine fond--bleu-marine' : 'bordure--jaune texte--bleu-marine fond--blanc'
    },
    couleurIcone: function () {
      return this.ouvrirRecherche ? 'blanc' : 'bleu-marine'
    },
    majActionAbsente: function () {
      return (this.estMobile) ? false : !this.actionAbsente
    }
  },
  mounted () {
    document.body.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        if (this.rechercheVisible) {
          this.gestionBarreRecherche()
        }
      }
    })
  },
  methods: {
    gestionBarreRecherche: function () {
      this.estMobile = (window.innerWidth < 768)
      if (this.rechercheVisible) {
        const baseUrl = this.localePath('recherche')
        const url = baseUrl + '?q=' + encodeURIComponent(this.chaineRecherche)
        window.open(url, '_self')
      } else {
        this.ouvrirRecherche = !this.ouvrirRecherche
        this.ouvrirRecherche ? this.ouvertureRecherche() : this.fermetureRecherche()
      }
    },
    annulerRecherche: function () {
      this.ouvrirRecherche = false
      this.chaineRecherche = ''
      this.fermetureRecherche()
    },
    fermetureRecherche: function () {
      this.rechercheVisible = false
      document.getElementById('recherche').value = ''
    },
    ouvertureRecherche: function () {
      this.actionAbsente = this.majActionAbsente

      if (this.estMobile) {
        this.rechercheVisible = true
      }
    },
    apresDisparitionDesActions: function () {
      this.rechercheVisible = true
    },
    apresDisparitionBarreRecherche: function () {
      this.actionAbsente = this.majActionAbsente
    },
    pendantApparitionBarreRecherche: function () {
      document.getElementById('recherche').focus()
    }
  }
}
