import { render, staticRenderFns } from "./default.vue?vue&type=template&id=139df246&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./default.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PolitiqueTemoins: require('/usr/src/nuxt-app/components/PolitiqueTemoins.vue').default,Header: require('/usr/src/nuxt-app/components/Header.vue').default,BoutonRetourHautDePage: require('/usr/src/nuxt-app/components/BoutonRetourHautDePage.vue').default,Footer: require('/usr/src/nuxt-app/components/Footer.vue').default})
