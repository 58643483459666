
import { getTemoins } from '~/assets/js/utiles.js'
export default {
  name: 'PolitiqueTemoins',
  props: {
    titre: {
      type: String,
      required: true
    },
    texte: {
      type: String,
      required: true
    },
    texteBouton: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      estOuvert: false,
      nomTemoins: 'utilisation-temoins'
    }
  },
  mounted () {
    setTimeout(this.ouverture, 200)

    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.fermeture()
      }
    })
  },
  methods: {
    ouverture () {
      if (!getTemoins(this.nomTemoins, document.cookie)) {
        this.estOuvert = true
      }
    },
    fermeture () {
      this.estOuvert = false
      const aujourdhui = new Date()
      const expiration = new Date(aujourdhui.setMonth(aujourdhui.getMonth() + 1))
      document.cookie = this.nomTemoins + '=true;expires=' + expiration
    },
    tabIndex () {
      return (this.estOuvert) ? '0' : '-1'
    }
  }
}
