
export default {
  name: 'BoutonRetourHautDePage',
  data () {
    return {
      boutonEstVisible: false,
      positionTop: null
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('touchmove', this.onScroll)
  },
  computed: {
    barreEstVisible () {
      // Signal de visibilité pour la barre orange en format mobile
      // Étant un sticky, son min est 0
      return this.positionTop === 0
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.boutonEstVisible = top > 20 // Signal de visibilité pour la flèche bleu en format desktop

      if (top === 0) {
        this.positionTop = 1
      }

      if (this.positionTop !== 0) {
        this.positionTop = document.querySelector('.conteneur-boutons').getBoundingClientRect().top
      }
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    source: function (nom) {
      return require('~/assets/icons/' + nom + '.svg')
    }
  }
}
