
export default {
  computed: {
    langueCible: function () {
      const currentLocale = this.$i18n.locale

      return this.$i18n.locales.filter(i => i.code !== currentLocale)[0]
    }
  }
}
